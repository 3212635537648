import React from 'react'
import "./css/Gallery.css"

const Gallery = () => {
  return (
    <>
    <div className="container-fluid">
    <div className="first">
        
        <div className="one">
            <img src="./images1/Home-sports.jpeg" alt="" />
        </div>
        <div className="one">
            <img src="./images1/gallery1.jpeg" alt="" />
        </div>
        <div className="one">
            <img src="./images1/gallery3.jpeg" alt="" />
        </div><div className="one">
            <img src="./images1/skilled-staff.jpeg" alt="" />
        </div>
    </div>
        <div className="first">
        
            <div className="one">
                <img src="./images1/gallery4.jpeg" alt="" />
            </div>
            <div className="one">
                <img src="./images1/gallery5.jpeg" alt="" />
            </div><div className="one">
                <img src="./images1/home3.jpeg" alt="" />
            </div><div className="one">
                <img src="./images1/gallery6.jpeg" alt="" />
            </div>
        </div>
        <div className="first">
            <div className="one">
                <img src="./images1/home1.jpeg" alt="" />
            </div>
            <div className="one">
                <img src="./images1/home2.jpeg" alt="" />
            </div><div className="one">
                <img src="./images1/gallery7.jpeg" alt="" />
            </div><div className="one">
                <img src="./images1/home4.jpeg" alt="" />
            </div>
        </div>
    </div>
    </>
  )
}

export default Gallery